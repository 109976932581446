body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Verdana',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 h1, h2, h3 {
  font-family: Georgia, serif;
 }

 /* Header */

 .header-box{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top:2%;
  padding-bottom: 2%;
 }

 .header-logo{
  width:35%
 }

 .header-left{
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-right: 2%;
  align-items: baseline;
 }
 .fb-header-box{
  align-self: center;
 }
 .fb-header{
  height: 40px;
  color: darkblue; 
  cursor: pointer; 
}
.fb-header:hover{
  color:blue;
}

.btn-header{
 padding:15px 20px 15px 20px;
 border: none;
 border-radius: 25px;
 background-color: rgba(176, 131, 72, 0.868);
 box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
 color:white;
 font-weight: bold;
 font-size: large;
 cursor: pointer;
}
.btn-header:hover {
  background-color: rgba(226, 149, 48, 0.868);
}

/* Hero section */

.hero-img {
  position: relative;
  height: 100vh;
}

.hero-content{
  display:flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width:100%;
} 
.hero-text{
  display: flex;
  justify-content: center;
  font-family: Helvetica, sans-serif;
  font-size: 100px;
  font-weight: bold;
  background-color: rgba(255,255,255,0.2);
  line-height: 100px;
  padding-left: 1%;
  padding-right: 1%;
  
}

/* Main section */

.main-content{
  display:flex;
  flex-direction: row;
  justify-content: center; 
  height:auto;
  width:100%;
}

.ukr-box, .fi-box{
  background-color:white;
  box-shadow: 10px  10px lightgray;
  margin: 2%;
  padding:2%;
  border-radius: 25px;
  border-style: solid;
  border-color:lightgray;
  border-width: 1px;
}
.ukr-price, .fi-price{
  display: flex;
  flex-direction: row;
  align-items: center;
  ;

}
.title {
  font-weight: bold;
  
}
.subtitle{
  font-weight: bold;
}

.description{
  text-align: center;
  line-height: 1.8;
}
h3 {
  text-decoration-line: underline;
}
ul{
  text-align: left;
  font-family: Verdana, sans-serif;
  line-height: 1.8;
  list-style: none;
}

ul li:before {
  content: '✓  ';
  color: brown;
}

/* Footer */

.footer-box{
  display:flex;
  flex-direction: column;
  background-color: rgba(176, 131, 72, 0.868);
  width: 100%;
  height:auto;
  margin-top: 3%;


}
.footer-info-box{
  display:flex;
  flex-direction: row;
 justify-content: space-between;
 padding-left: 2%;
}

.footer-info{
  display:flex;
  flex-direction: column; 
  justify-content: center;
  align-items: flex-start;
  padding-right: 1%;
  
}

.footer-fb-box{
  align-self: center;
  padding-top: 10px;
}
.fb-footer{
  height: 30px;
  color: white;
  cursor: pointer;
}
.fb-footer:hover{
  color:blue;
}
.footer-title{
  color:white;
}
.email-box, .phone-box, .location-box, .copyright-box, .footer-fb-box{
  display:flex;
  flex-direction: row;
  gap:10px;
  line-height: 1.7;
  color:white;
}
.phone, .email, .location{
  font-size: large;
}
.email{
  position: relative;
  text-decoration: none;
  cursor: pointer;
}
.email::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: white;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }

.email:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}


.copyright-box{
  align-self: center;
  padding-bottom: 1%;
  align-items: center;
}

.footer-logo-box{
  align-self: flex-start;
 
}
.footer-logo{
  width:60%;
}
/* Extra small devices (phones, 375px and down) */
@media only screen and (max-width: 375px) {

  /* Header */
.header-box{
  flex-direction: column;
}
 .header-left{
  flex-direction:column-reverse ;
  padding-top: 3%;
  padding-bottom: 3%;
  width: 100%;
 }
 .fb-header-box{
  display: none;
  visibility: hidden;
 }
 .fb-header{
  width: 0px;
 }
 .header-btn-box{
  align-self: center;
 }
 .btn-header{
  width: 250px;
 }

  /* Hero section */
  
  .hero-img {
    position: relative;
    height: 30vh;
  }
  
  .hero-content{
    height: auto;
    width:100%;
  } 
  .hero-text{
    display:flex;
    justify-content: center;
    font-size: 35px;
    font-weight: bold;
    
  }
  
  /* Main section */
  
  .main-content{
    flex-direction: column;
    justify-content: start;
    height:auto;
  }
  
/* Footer */
  
   .footer-info-box{
    flex-direction: column;
   }
   .footer-info{
    align-items: center;
   }
   .contact-info-box{
    align-self: center;
   }
   .fb-footer,.footer-fb-box{
    height: 70px;
   }
   .footer-fb-box{
   padding-top: 50px;
    justify-content: center;
   }
  }

/* Small devices (phones, 380px and up) */
@media only screen and (min-width: 380px) {

/* Header */
.header-box{
  flex-direction: column;
}

.fb-header-box{
  display: none;
  visibility: hidden;
 }

 .header-btn-box{
  align-self: center;
 }
 .btn-header{
  width: 250px;
 }
/* Hero section */

.hero-img {
  position: relative;
  height: 30vh;
}

.hero-content{
  height: 30vh;
  width:100%;
} 
.hero-text{
  display:flex;
  justify-content: center;
  font-size: 35px;
  font-weight: bold;
  
}

/* Main section */

.main-content{
  flex-direction: column;
  justify-content: start;
  height:auto;
}

/* Footer */
  
.footer-info-box{
  flex-direction: column;
 }
 .footer-info{
  align-items: center;
 }
 .contact-info-box{
  align-self: center;
 }
 .fb-footer,.footer-fb-box{
  height: 80px;
 }
 .footer-fb-box{
 padding-top: 50px;
  justify-content: center;
 }
 .footer-logo-box{
  justify-content: center;
  align-self: center;
 }


}
/* Medium devices (landscape tablets, 600px and up) */
@media only screen and (min-width: 600px) {

/* Header */
.header-box{
  flex-direction: row;
}

  /* Hero section */
  .hero-img {
    position: relative;
    height: 70vh;
  }
 
  .hero-content{
    height: 50vh;
    width:100%;
  } 
  .hero-text{
    display: flex;
    justify-content: center;
    font-size: 50px;
    font-weight: bold;
    
  }

  /* Main section */

  .main-content{
    display:flex;
    flex-direction: column;
    justify-content: start;
    height:auto;

  }

  /* Footer */

  .footer-info-box{
    justify-content: center;
    padding-top: 5%;
   }

   .footer-info{
    align-items: center;
   }
   
   .fb-footer,.footer-fb-box{
    height: 70px;
   }
   .footer-fb-box{
   padding-top: 50px;
    justify-content: center;
   }
  .footer-logo-box{
    width: 100%;
    align-content: center;
  }
   .footer-logo{
    width: 30%;
   }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  /* Header */

  .fb-header-box{
    display:block;
    visibility: visible;
    align-self: center;
   }
   .fb-header{
    height: 40px;
    color: darkblue; 
    cursor: pointer; 
  }
  .fb-header:hover{
    color:blue;
  }
  .btn-header {
    width: 150px;
  }

  /* Hero section */
  
  .hero-text{
    display: flex;
    justify-content: center;
    font-size: 55px;
  }
  .hero-content{
    height: 60vh;
    width:100%;
  } 

  /* Main section */
  .main-content{
    display:flex;
    flex-direction: row;
    justify-content: center;
    height:auto;

  }

  /* Footer */

  .footer-box{
    width: 100%;
  }
  .footer-info-box{
    display:flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    padding-top:1%;
    padding-left:5%;
    
  
 }

 .footer-fb-box{
  padding-top: 10px;
   justify-content: flex-start;
   height: 40px;
  }
  .fb-footer{
    height: 40px;
  }
 .footer-logo-box{
   width: 40%;
   align-content: flex-end;
   
 }
  .footer-logo{
   width: 70%;
  }
}

/*X large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
/* Header */

.header-logo {
  width: 45%;
}
.btn-header {
  width: 200px;
  font-size: x-large;
  padding:15px 10px 15px 10px;
}

.fb-header, {
  height: 50px;
}
 
 /* Hero section */
  .hero-content{
    height: 50vh;
    width:100%;
  } 

  .hero-text{
    display: flex;
    justify-content: center;
    font-size: 80px;
    font-weight: bold;
  }

  /* Main section */
  .main-content{
    display:flex;
    flex-direction: row;
    justify-content: center;
    height:auto;
  }

  /* Footer */
  .email-logo, .whatsup-logo{
    height: 1.2em;
  }
  .email-box, .phone-box{
    align-items: center;
  }
  .phone, .email{
    font-size: large;
  }
  .fb-footer,.footer-fb-box{
    height: 40px;
  }
}

 /* XX large devices (large laptops and desktops, 2500px and up) */
 @media only screen and (min-width: 1810px) {

  /* Header */

  .btn-header {
    width: 250px;
    font-size: xx-large;
  }
  .header-logo{
    width: 55%;
  }
 

  /* Hero section */
   .hero-content{
     height: 40vh;
     width:100%;
   } 
 
   .hero-text{
     display: flex;
     justify-content: center;
     font-size: 65px;
     font-weight: bold;
   }
 
   /* Main section */
   .main-content{
     display:flex;
     flex-direction: row;
     justify-content: center;
     height:auto;

   }
  
   h3 {
    font-size: x-large;
   }
   
   .description {
    text-align: center;
    line-height: 1.5;
    font-size: x-large;
  }

  ul{
    font-size: x-large;
  }
  .ukr-price, .fi-price, .copyright{
    font-size: x-large;
  }

  /* Footer */
  .footer-title h2{
    font-size: 40px;
  }

  .phone-box, .email-box {
    flex-direction: row;
    align-items: center;
  }
 
    .email, .phone {
    font-size: 30px;
  }
  
  .svg-inline--fa {
    height: 2em;
  }
  .fb-footer, .footer-fb-box{
    height: 60px;
  }

  /*Header*/
  .fb-header {
    height:60px;
  }
  
}
   /* XXX large devices (large laptops and desktops, 2850px and up) */
 @media only screen and (min-width: 2550px) {

/*Header*/
  .btn-header {
    width: 350px;
    font-size: xxx-large;
  }

 
   /* Main section */
   .main-content{
     display:flex;
     flex-direction: row;
     justify-content: center;
     height:auto;
 
   }

    h3, h1, h2{
      font-size: xx-large;
    }
   .description {
    font-size: xx-large;
  }
  ul{
    font-size: xx-large;
  }
  .ukr-price, .fi-price, .copyright{
    font-size: xx-large;
  }
   /*Footer*/
   .svg-inline--fa {
    height: 2em;
  }
  .fb-footer, .footer-fb-box {
    height: 80px;
}
/*Header*/
.fb-header, .fb-header-box {
  height:90px;
}
 }
